import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

interface IFormatDatePipeOptions {
	showInvalid:boolean;
}
@Pipe({name: 'formatTime'})
export class FormatTimePipe implements PipeTransform {
	transform(value:any, options?:IFormatDatePipeOptions) {
		const mDate = moment(value);
		if(!mDate.isValid() && (!options || !options.showInvalid))
			return '';
		return mDate.format('HH:mm:ss');
	}
}
@Pipe({name: 'formatTimeHHmm'})
export class FormatTimeHHmmPipe implements PipeTransform {
	transform(value:any, options?:IFormatDatePipeOptions) {
		const mDate = moment(value);
		if(!mDate.isValid() && (!options || !options.showInvalid))
			return '';
		return mDate.format('HH:mm');
	}
}
@Pipe({name: 'formatTimeStr'})
export class FormatTimeStrPipe implements PipeTransform {
	transform(value:any) {
		return (value ? value : '').replace(/^((0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){1}).*/, '$1');
	}
}
@Pipe({name: 'formatDate'})
export class FormatDatePipe implements PipeTransform {
	transform(dateAndTime:any, options?:IFormatDatePipeOptions) {
		const mDate = moment(dateAndTime);
		if(!mDate.isValid() && (!options || options.showInvalid))
			return '';
		return mDate.format('YYYY-MM-DD');
	}
}
@Pipe({name: 'formatDateMonth'})
export class FormatDateMonthPipe implements PipeTransform {
	transform(dateAndTime:any, options?:IFormatDatePipeOptions) {
		const mDate = moment(dateAndTime);
		if(!mDate.isValid() && (!options || options.showInvalid))
			return '';
		return mDate.format('YYYY-MM');
	}
}
@Pipe({name: 'formatDateAndTime'})
export class FormatDateAndTimePipe implements PipeTransform {
	transform(dateAndTime:any, options?:IFormatDatePipeOptions) {
		const mDate = moment(dateAndTime);
		if(!dateAndTime || (!mDate.isValid() && (!options || options.showInvalid)))
			return '';
		return mDate.format('YYYY-MM-DD HH:mm');
	}
}
@Pipe({name: 'formatDateAndTimeFull'})
export class FormatDateAndTimeFullPipe implements PipeTransform {
	transform(dateAndTime:any, options?:IFormatDatePipeOptions) {
		const mDate = moment(dateAndTime);
		if(!mDate.isValid() && (!options || options.showInvalid))
			return '';
		return mDate.format('YYYY-MM-DD HH:mm:ss.SSSZ');
	}
}
@Pipe({name: 'formatCalendar'})
export class FormatCalendarPipe implements PipeTransform {
	transform(dateAndTime:any) {
		return moment(dateAndTime).calendar();
	}
}
@Pipe({name: 'formatDateAgo'})
export class FormatDateAgoPipe implements PipeTransform {
	transform(dateAndTime:any) {
		return (dateAndTime ? moment(dateAndTime).fromNow() : '');
	}
}
@Pipe({name: 'formatDurationMs'})
export class FormatDurationMsPipe implements PipeTransform {
	transform(durationMs:any, unit?:moment.unitOfTime.Base) {
		return moment.duration(durationMs, unit ? unit : 'milliseconds').humanize();
	}
}
@Pipe({name: 'formatDurationExactMs'})
export class FormatDurationExactMsPipe implements PipeTransform {
	transform(durationMs:any) {
		var duration = moment.duration(durationMs, 'milliseconds');
		// XXX Until https://github.com/foretagsplatsen/numbro/issues/76 is resolved
		var minStr = duration.minutes() < 10 ? ('0' + duration.minutes()) : duration.minutes();
		var secStr = duration.seconds() < 10 ? ('0' + duration.seconds()) : duration.seconds();
		var hourStr = duration.hours() < 10 ? ('0' + duration.hours()) : duration.hours();
		var str = hourStr + ':' + minStr +  ':' + secStr;
		if(duration.days() > 0)
			str = duration.days() + ':' + str;
		if(duration.months() > 0)
			str = duration.months() + ':' + str;
		if(duration.years() > 0)
			str = duration.years() + ':' + str;
		return str;
	}
}
/** Format strings suitable for moment.duration() to a human readable duration */
@Pipe({name: 'formatDuration'})
export class FormatDurationPipe implements PipeTransform {
	transform(duration:any, maxUnit?:string) {
		if(duration === '') // Don't print 'a few seconds' for 0
			return '';
		duration = moment.duration(duration);
		if(maxUnit && maxUnit === 'hours') { // Format in minutes or hours, not days, months, or years
			if(duration.asHours() < 2)
				return moment.localeData().relativeTime(Math.round(duration.asMinutes()), true, 'mm', false);
			else if(duration.asHours() < 12)
				return moment.localeData().relativeTime(Math.round(duration.asHours() * 10) / 10, true, 'hh', false);
			else
				return moment.localeData().relativeTime(Math.round(duration.asHours()), true, 'hh', false);
		}
		return moment.duration(duration).humanize();
	}
}
@Pipe({name: 'formatFalogbookTime'})
export class FormatFaLogbookTimePipe implements PipeTransform {
	transform(dateAndTime:any) {
		dateAndTime = Number(dateAndTime);
		return dateAndTime ? moment(dateAndTime).locale('en').format('DD-MM-YYYY HH:mm A') : '';
	}
}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base.component';
import { InfostarsToolsService } from '../services/InfostarsTools.service';

export interface VehicleTreeApi {
	getChecked(node:any):boolean;
	setChecked(node:any, checked:boolean):void;
	onVehicleToggled(vehicle:any):void;
	onVehicleGroupToggled(node:any):void;
}

@Component({
	selector: 'vehicle-tree',
	templateUrl: './vehicle-tree.html',
})
export class VehicleTreeComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input() rootNode:any;
	// @Input() set vehicleNode(vehicleNode:any) { this._vehicleNode = vehicleNode; if(this.level === 0) { this.rootNode = vehicleNode; }};
	// get vehicleNode():any { return this._vehicleNode; }
	// private _vehicleNode:any;
	@Input() vehicleNode:any;
	@Input() level:number;
	@Input() api:VehicleTreeApi;

	public groupMargin = {'margin-left': 10 + 'px'};
	public vehicleFilter = {name: ''};

	constructor(
		protected InfostarsTools: InfostarsToolsService,
	) { super(InfostarsTools); }

	ngOnInit() {
		this.groupMargin['margin-left'] = this.level * 10 + 'px';
		this.onFilterChange();
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	/** Walk the whole tree and set the checked property for all vehicles with the given id */
	protected setCheckedVehicleRecursive(id:any, currNode:any, nodeChecked:boolean, childrenProp:string, groupsProp:string) {
		if(currNode.id === id && currNode.vehicle)
			this.api.setChecked(currNode, nodeChecked);
		(currNode[childrenProp] || []).forEach((n:any) => this.setCheckedVehicleRecursive(id, n, nodeChecked, childrenProp, groupsProp));
		(currNode[groupsProp] || []).forEach((n:any) => this.setCheckedVehicleRecursive(id, n, nodeChecked, childrenProp, groupsProp));
	}
	/** Recursively set the checked property for a whole subtree (vehicle group) */
	protected setCheckedVehicleTreeNodeRecursive(currNode:any, nodeChecked:boolean, childrenProp:string, groupsProp:string) {
		if(currNode.vehicle)
			this.setCheckedVehicleRecursive(currNode.id, this.rootNode, nodeChecked, childrenProp, groupsProp);
		else
			this.api.setChecked(currNode, nodeChecked);
		(currNode[childrenProp] || []).forEach((n:any) => this.setCheckedVehicleTreeNodeRecursive(n, nodeChecked, childrenProp, groupsProp));
		(currNode[groupsProp] || []).forEach((n:any) => this.setCheckedVehicleTreeNodeRecursive(n, nodeChecked, childrenProp, groupsProp));
	}

	/** Handle the click on the vehicle name or icon (as opposed to the input directly) */
	public onVehicleClick (vehicle:any) {
		this.api.setChecked(vehicle, !this.api.getChecked(vehicle));
		this.api.onVehicleToggled(vehicle);
	}
	/** Handle the input checkbox change on vehicles */
	public onVehicleToggled (vehicle:any) {
		let nodeChecked = this.api.getChecked(vehicle);
		this.setCheckedVehicleRecursive(vehicle.id, this.rootNode, nodeChecked, 'vehicles', 'childGroups');
		this.api.onVehicleToggled(vehicle);
	}
	/** Handle the input checkbox change on vehicle groups */
	public onVehicleGroupToggled(vGroup:any) {
		let nodeChecked = this.api.getChecked(vGroup);
		// Toggle all child groups and vehicles
		this.setCheckedVehicleTreeNodeRecursive(vGroup, nodeChecked, 'vehicles', 'childGroups');
		this.api.onVehicleGroupToggled(vGroup);
	}

	private filterVehicle(node:any, filterText:string) {
		if(!node)
			return;
		(node.vehicles || []).forEach((vehicle:any) =>  {
			vehicle.filtered = filterText === '' || vehicle.title.toLocaleLowerCase().includes(filterText);
		});
		(node.childGroups || []).forEach((cGroup: any) =>  {
			this.filterVehicle(cGroup, filterText);
		});
	}

	public onFilterChange () {
		let filterText = this.vehicleFilter.name.toLocaleLowerCase();
		this.filterVehicle(this.vehicleNode, filterText);
	}
}

<nav class="top-bar row" data-topbar role="navigation">
	<ul class="title-area">
		<li class="name">
			<h1 class="menu-icon">
				<!-- XXXAngularIO <a (click)="onOpenMenu($event)" [ngClass]="{'hide': !$root.filterControl.openMenu}"><span>{{ 'filter' | translate }}</span></a> -->
				<a (click)="onOpenMenu($event)" [ngClass]="{'hide': !enableFilter}"><span>{{ 'filter' | translate }}</span></a>
			</h1>
		</li>
		<li class="toggle-topbar menu-icon">
			<a><span>{{'menu'|translate}}</span></a>
		</li>
	</ul>

	<section class="top-bar-section">
		<ul class="right">
			<li routerLinkActive="active" [hidden]="loggedIn"><a routerLink="/{{ activeLang }}" (click)="fixTopbarMobile($event)">Home</a></li>
			<li routerLinkActive="active" [hidden]="loggedIn"><a routerLink="/{{ activeLang }}/login" (click)="fixTopbarMobile($event)">{{ 'login' | translate }}</a></li>
			<li routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; !isAnonLogin)">
				<a routerLink="/{{ activeLang }}/login" (click)="fixTopbarMobile($event)">
					{{ username }}
					<span [hidden]="!forcedAccountName" class="forced-account-username">({{ forcedAccountName }})</span>
				</a>
			</li>
			<li [hidden]="!(loggedIn &amp;&amp; isAnonLogin)"><a (click)="onLogout()">{{ 'logout' | translate }}</a></li>
			<!-- Main menu for desktop -->
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB'">
				<a routerLink="/{{ activeLang }}/dispolight">{{ 'dispoLight' | translate }}</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; trackType !== 'ANIMAL' &amp;&amp; !(userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
				<a routerLink="/{{ activeLang }}/logbook">{{ 'logbook' | byTrackType:trackType | translate }}</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; trackType === 'ANIMAL' &amp;&amp; !(userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
				<a routerLink="/{{ activeLang }}/assetlogbook">{{ 'logbook' | byTrackType:trackType | translate }}</a>
			</li>
			<li class="show-for-large-up has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'">
				<a>{{'logbook'|translate}}</a>
				<ul class="dropdown">
					<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
						<a routerLink="/{{ activeLang }}/logbook">{{ 'logbook' | translate }}</a>
					</li>
					<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
						<a routerLink="/{{ activeLang }}/assetlogbook">{{ 'logbook_animal' | translate }}</a>
					</li>
				</ul>
			<li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_ALARMSTAR'">
				<a routerLink="/{{ activeLang }}/alarmstar">{{ 'alarmStar' | translate }}</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FAFB'">
				<a routerLink="/{{ activeLang }}/falogbook">{{ 'faLogbook' | translate }}</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FUEL_TAB'">
				<a routerLink="/{{ activeLang }}/fuelstar">{{ 'fuelStar' | translate }}</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_TAXI_TOURS'">
				<a routerLink="/{{ activeLang }}/taxilog">{{ 'ytTaxilog' | translate }}</a>
			</li>
			<li class="show-for-large-up" routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_MAINTENANCE'">
				<a routerLink="/{{ activeLang }}/maintenance">{{ 'maintenanceTool' | translate }}</a>
			</li>
			<!-- == Main menu for mobile == -->
			<li class="has-dropdown not-click hide-for-large-up" [hidden]="!(loggedIn)">
				<a>{{'overview'|translate}}</a>
				<ul class="dropdown">
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB'">
						<a routerLink="/{{ activeLang }}/dispolight" (click)="fixTopbarMobile($event)">{{ 'dispoLight' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; trackType !== 'ANIMAL' &amp;&amp; !(userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
						<a routerLink="/{{ activeLang }}/logbook" (click)="fixTopbarMobile($event)">{{ 'logbook' | byTrackType:trackType | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn &amp;&amp; trackType === 'ANIMAL' &amp;&amp; !(userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'))" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
						<a routerLink="/{{ activeLang }}/assetlogbook" (click)="fixTopbarMobile($event)">{{ 'logbook' | byTrackType:trackType | translate }}</a>
					</li>
					<li class="has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'">
						<a>{{'logbook'|translate}}</a>
						<ul class="dropdown">
							<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
								<a routerLink="/{{ activeLang }}/logbook" (click)="fixTopbarMobile($event)">{{ 'logbook' | translate }}</a>
							</li>
							<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_DISPO_TAB AND SHOW_DISPO_LOGBOOK'">
								<a routerLink="/{{ activeLang }}/assetlogbook" (click)="fixTopbarMobile($event)">{{ 'logbook_animal' | translate }}</a>
							</li>
						</ul>
					<li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_ALARMSTAR'">
						<a routerLink="/{{ activeLang }}/alarmstar" (click)="fixTopbarMobile($event)">{{ 'alarmStar' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FAFB'">
						<a routerLink="/{{ activeLang }}/falogbook" (click)="fixTopbarMobile($event)">{{ 'faLogbook' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_FUEL_TAB'">
						<a routerLink="/{{ activeLang }}/fuelstar" (click)="fixTopbarMobile($event)">{{ 'fuelStar' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_TAXI_TOURS'">
						<a routerLink="/{{ activeLang }}/taxilog-noparam" (click)="fixTopbarMobile($event)">{{ 'ytTaxilog' | translate }}</a>
					</li>
					<li routerLinkActive="active" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_MAINTENANCE'">
						<a routerLink="/{{ activeLang }}/maintenance" (click)="fixTopbarMobile($event)">{{ 'maintenanceTool' | translate }}</a>
					</li>
				</ul>
			</li>
			<!-- ========================== -->
			<li class="has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'SHOW_ADMIN_TAB'">
				<a>{{'settings'|translate}}</a>
				<ul class="dropdown">
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ADDITIONAL_SETTINGS'">
						<a routerLink="/{{ activeLang }}/admin/usersettings" (click)="fixTopbarMobile($event)">{{'settings'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ZONES'">
						<a routerLink="/{{ activeLang }}/admin/zone" (click)="fixTopbarMobile($event)">{{'geoAreas'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_VEHICLES'">
						<a routerLink="/{{ activeLang }}/admin/vehicle" (click)="fixTopbarMobile($event)">{{'vehicles' | byTrackType:trackType | translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_VEHICLE_GROUPS'">
						<a routerLink="/{{ activeLang }}/admin/vehiclegroup" (click)="fixTopbarMobile($event)">{{'vehicleGroups' | byTrackType:trackType | translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DRIVERS'">
						<a routerLink="/{{ activeLang }}/admin/driver" (click)="fixTopbarMobile($event)">{{'vehicleDriver'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DRIVER_CARDS'">
						<a routerLink="/{{ activeLang }}/admin/drivercard" (click)="fixTopbarMobile($event)">{{'driverCards'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DRIVER_GROUPS'">
						<a routerLink="/{{ activeLang }}/admin/drivergroup" (click)="fixTopbarMobile($event)">{{'driverGroups'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_USERS'">
						<a routerLink="/{{ activeLang }}/admin/user" (click)="fixTopbarMobile($event)">{{'user'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_USER_RIGHT_GROUPS'">
						<a routerLink="/{{ activeLang }}/admin/userrightgroup" (click)="fixTopbarMobile($event)">{{'user'|translate}} {{'rights'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ACCOUNTS'">
						<a routerLink="/{{ activeLang }}/admin/account" (click)="fixTopbarMobile($event)">{{'account'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_ROUTINES'">
						<a routerLink="/{{ activeLang }}/admin/routine" (click)="fixTopbarMobile($event)">{{'routines'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_VEHICLE_EQUIPMENT_MAPPINGS'">
						<a routerLink="/{{ activeLang }}/admin/vehicleequipment" (click)="fixTopbarMobile($event)">{{'vehicleEquipmentMapping' | byTrackType:trackType | translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_GPS_EQUIPMENTS'">
						<a routerLink="/{{ activeLang }}/admin/gpsequipment" (click)="fixTopbarMobile($event)">{{'gpsDevices'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_STATIC_ROUTE'">
						<a routerLink="/{{ activeLang }}/admin/staticroute" (click)="fixTopbarMobile($event)">{{'staticRoutes'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_DATA_INTERFACE'">
						<a routerLink="/{{ activeLang }}/admin/datainterface" (click)="fixTopbarMobile($event)">{{'dataInterface'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_USER_TRANSLATION'">
						<a routerLink="/{{ activeLang }}/admin/usertranslation" (click)="fixTopbarMobile($event)">{{'userTranslation'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_TOUR_STATUS_CUSTOM'">
						<a routerLink="/{{ activeLang }}/admin/tourstatuscustom" (click)="fixTopbarMobile($event)">{{'tourStatusCustom'|translate}}</a>
					</li>
					<li routerLinkActive="active">
						<a routerLink="/{{ activeLang }}/setup" (click)="fixTopbarMobile($event)">{{'setupTitle'|translate}}</a>
					</li>
				</ul>
			</li>
			<!-- ========================== -->
			<li class="has-dropdown not-click" [hidden]="!(loggedIn)" *ngIf="userRights | hasPermission:'ROLE_SUPER_SYSTEM_ADMINISTRATOR'">
				<a>{{'setup'|translate}}</a>
				<ul class="dropdown">
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'SHOW_STATI'">
						<a routerLink="/{{ activeLang }}/admin/status" (click)="fixTopbarMobile($event)">{{'status'|translate}}</a>
					</li>
					<li routerLinkActive="active" *ngIf="userRights | hasPermission:'ADD_GPS_EQUIPMENTS'">
						<a routerLink="/{{ activeLang }}/admin/equipmentaccsetup" (click)="fixTopbarMobile($event)">{{'setupTitle'|translate}}</a>
					</li>
				</ul>
			</li>
			<li class="active" [hidden]="!updateAvailable"> <!-- active hightlights it, it's a bit of a misuse but visually fine -->
				<a (click)="onUpdateApp()" title="{{ 'newVersionAvailablePleaseUpdate' | translate }}"><i class="fi-refresh"></i>&nbsp;</a>
			</li>
		</ul>
	</section>
</nav>

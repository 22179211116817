import 'zone.js';
import 'reflect-metadata';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ppHasErrorDirective, ppIsErrorDirective } from './directives/pp_has_error';
import { ByTrackTypePipe } from './pipes/ByTrackType.pipe';
import { TranslateEnumPipe } from './pipes/TranslateEnum.pipe';
import { FormatTimePipe, FormatTimeHHmmPipe, FormatTimeStrPipe, FormatDatePipe, FormatDateAndTimePipe, FormatDateAgoPipe, FormatDurationMsPipe, FormatDurationExactMsPipe, FormatDateAndTimeFullPipe, FormatDurationPipe, FormatFaLogbookTimePipe, FormatDateMonthPipe } from './pipes/FormatDate.pipe';
import { FormatBooleanStatusPipe, FormatDataMBPipe, FormatDistanceKmPipe, FormatFuelUsageLiterPipe, FormatLatLonPipe, FormatMoneyPipe, FormatPercentPipe, FormatSpeedKmhPipe, FormatTemperatureCPipe, CapitalisePipe, FirstLetterPipe, FormatNumberLocalePipe } from './pipes/FormatInfostars.pipe';
import { OrderByPipe } from './pipes/OrderBy.pipe';
import { FilterPipe } from './pipes/Filter.pipe';
import { EmptyToEndPipe } from './pipes/EmptyToEnd.pipe';
import { ShortenedPipe } from './pipes/Shortened.pipe';
import { HasPermissionPipe } from './pipes/HasPermission.pipe';
import { ppDraggableDirective, ppDroppableDirective } from './directives/pp_draggable_droppable.directive';
import { MessagesComponent } from './components/messages.controller';
import { ppDateFutureValidator, ppEqualToValidator, ppTimeFutureValidator, ppAtLeastNCheckedValidator, veAuxStatiUniqueValidator } from './directives/pp_validators';
import { FilterComponent } from './components/filter/filter.component';
import { NavbarCtrl } from './components/navbar/navbar.controller';
import { LogoHeaderBarCtrl } from './components/logo-header-bar.controller';
import { DateTimeComponent } from './components/dateTime.component';
import { DateComponent } from './components/date.component';
import { VehicleTreeComponent } from './components/vehicle-tree.component';
import { DriverTreeComponent } from './components/driver-tree.component';
import { TetherDropDirective } from './directives/tether-drop.directive';
// deprecated see tetherDrop example in routinelist.html import { ClickAnywhereButHereDirective } from './directives/click-anywhere-but-here.directive';
import { TimeIntervalComponent } from './components/time-interval.component';
import { TourFieldToCodePipe } from './pipes/TourFieldToCode.pipe';
import { LanguageChooserCtrl } from './components/language-chooser.controller';
import { TimeUiComponent } from './components/time-ui.component';
import { IsArrayPipe } from './pipes/IsArray.pipe';
import { VehicleFilterPipe } from './pipes/VehicleFilter.pipe';

const exportedDeclarations = [
	// Components used in templates
	DateTimeComponent, DateComponent, TimeIntervalComponent, TimeUiComponent,
	NavbarCtrl, FilterComponent, LogoHeaderBarCtrl,MessagesComponent,
	VehicleTreeComponent, DriverTreeComponent, LanguageChooserCtrl,
	// Validators
	ppDateFutureValidator, ppTimeFutureValidator, ppAtLeastNCheckedValidator, ppEqualToValidator, veAuxStatiUniqueValidator,
	// Directives
	ppHasErrorDirective, ppIsErrorDirective, ppDraggableDirective, ppDroppableDirective, TetherDropDirective,
	// Pipes
	FormatTimePipe, FormatTimeHHmmPipe, FormatTimeStrPipe, FormatDatePipe, FormatDateMonthPipe, FormatDateAndTimePipe, FormatDateAndTimeFullPipe, FormatDateAgoPipe, FormatDurationPipe, FormatDurationMsPipe, FormatDurationExactMsPipe, FormatFaLogbookTimePipe,
	FormatMoneyPipe, FormatPercentPipe, FormatSpeedKmhPipe, FormatDistanceKmPipe, FormatFuelUsageLiterPipe, FormatLatLonPipe, FormatTemperatureCPipe,
	TranslateEnumPipe, EmptyToEndPipe, FormatBooleanStatusPipe, FormatDataMBPipe, FormatNumberLocalePipe, CapitalisePipe, FirstLetterPipe,
	ByTrackTypePipe, TourFieldToCodePipe,
	OrderByPipe, FilterPipe, ShortenedPipe,
	IsArrayPipe,
	HasPermissionPipe,
	VehicleFilterPipe
]

// A feature NgModule for the Angular app
@NgModule({
	declarations: [
		...exportedDeclarations
	],
	imports: [
		CommonModule, FormsModule,
		RouterModule.forChild([]),
		// // Third party modules
		TranslateModule.forChild({extend: true}),
	],
	exports: [
		...exportedDeclarations
	],
	providers: [ // Pipes we want to inject into components
		FirstLetterPipe
	]
})
export class AppSharedModule {
}

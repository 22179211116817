<canvas-to-png #canvasToPng></canvas-to-png>

<filter #filterComp>
	<div class="grid-frame vertical">
		<logo-header-bar></logo-header-bar>

		<div class="grid-block shrink wrap navbar">
			<div class="grid-content collapse">
				<navbar [filterComp]="filterComp"></navbar>
			</div>
		</div>
		<messages></messages>
		<div class="grid-block">
			<router-outlet></router-outlet>
		</div>
	</div>
</filter>

<div id="agbModal" class="reveal-modal" data-reveal aria-labelledby="agbNodalTitle" aria-hidden="true" role="dialog">
	<h2 id="agbNodalTitle">{{'termsAndConditions'|translate}}</h2>
	<div [innerHTML]="termsAndConditionsHtml"></div>
	<div class="action-buttons">
		<a class="button" (click)="onAcceptTerms()">{{'accept'|translate}}</a>
		<a class="button" (click)="onDeclineTerms()">{{'decline'|translate}}</a>
	</div>
</div>

<div id="ytPrivacyPolicyModal" class="reveal-modal" data-reveal aria-labelledby="ytPPNodalTitle" aria-hidden="true" role="dialog">
	<h2 id="ytPPNodalTitle">{{'ytPrivacyPolicy'|translate}}</h2>
	<div [innerHtml]="ytPrivacyPolicyHtml"></div>
	<div class="action-buttons">
		<a class="button" (click)="onCloseYTPP()">{{'close'|translate}}</a>
	</div>
</div>

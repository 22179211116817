// ATTENTION: Only include this using:
// import { config } from '../../environments/environment'

import infostarsLang_en from '../translations/I18NConstants_en.properties';
import infostarsLang_de from '../translations/I18NConstants_de.properties';
import infostarsLang_da from '../translations/I18NConstants_da.properties';
import infostarsLang_sk from '../translations/I18NConstants_sk.properties';
import infostarsLang_it from '../translations/I18NConstants_it.properties';
import infostarsLang_es from '../translations/I18NConstants_es.properties';
import numeral from "numeral";
import "numeral/locales";

let baseUrl = 'https://app.gps-infostars.com/infostarsWeb/app';
export function setUrlsFromLocation(conf:any, location:string) {
	// Examples:
	// Development server: basePath: '/infostarsWeb', baseUrl: 'http://localhost:8080/infostarsWeb', restBaseUrl: 'http://localhost:8080/infostarsWeb/rest' from location: 'http://localhost:8080/infostarsWeb/app/en/login'
	// Testserver: basePath: '/infostarsWeb', baseUrl: 'https://test.gps-infostars.com/infostarsWeb', restBaseUrl: 'https://test.gps-infostars.com/infostarsWeb/rest' from location 'https://test.gps-infostars.com/infostarsWeb/app/de/login'
	const removeAppPathSuffixRegex = /\/app($|\/).*/;
	// The window.location.pathname for the app is is usually /infostarsWeb/app, but the infostarsWeb part my vary from deployment to deployment, so we need to figure this out from window.location.pathname
	conf.basePath = location.replace(/https?:\/\/[^\/]+(\/.*)/, '$1'); // strip https://server.com prefix from e.g. https://server.com/infostarsWeb/app/en/login
	conf.basePath = conf.basePath.replace(removeAppPathSuffixRegex, ''); // e.g. /infostarsWeb/app/en/login -> /infostarsWeb
	conf.baseUrl = location.replace(removeAppPathSuffixRegex, ''); // Get e.g. https://server.com/infostarsWeb from location e.g. https://server.com/infostarsWeb/app/en/login
	conf.restBaseUrl = conf.baseUrl + '/rest';
}
/** Returns whether the app is running within a browser, instead of Server Side Rendering, aka Angular Universion
 * Instead of using this, you should write services that can be replaced by stubs / guards on the server, see ServerComet.service.ts */
export function rendersInBrowser() {
	return globalThis['window'] ? true : false;
}
let defaultCenter = { // Used for e.g. zip geocoding to sort the results
	lat: 52.5161,
	lng: 13.4656
};
let fallbackLang = 'de';

let appFeatures = {
	showABC: true,
	enableABC: true,
};
// Different feature set for a different subdomain
// let appFeaturesMyCountry = {
// 	showABC: true,
// 	enableABC: true,
// };
// let isMyCountry = rendersInBrowser() ? location.host === 'mycountry.gps-infostars.com' : false;
// if(isMyCountry)
// 	appFeatures = appFeaturesMyCountry;
// Used by TransferState angular API to skip re-doing HTTP requests
let infostarsTransferState = {
	appJson: 'appJson',
	formInfo: 'formInfo',
}
let validatorPatterns = {
	...(rendersInBrowser() ? (window as any).Foundation.libs.abide.settings.patterns : []), // see node_modules/foundation-sites/js/foundation/foundation.abide.js
	anything: /.*/,
	relaxed_password: /^[^\s].{4,}[^\s]$/,
	phone_number_noprefix: /^[1-9][0-9 ]{3,}$/,
	// No phone number, email or domain, with negative lookahead like /^(?!.*((PHONE_PATTERN)|(EMAIL_PATTERN)|(DOMAIN_PATTERN)))/
	// Phone pattern is home-made, basically at least 6 numbers with potential special character in between for obfuscation
	//                          ------------------------ phone number----------------------- | ----------------------------------------- email from foundation.abide.js ---------------------------------------------------------- |  -------- domain from foundation.abide.js --------------------
	no_contact_method: /^(?!.*(([0-9][!#$%^&*\(\)\-_=+{}\[\]\|;:"'<>,.?\/\t\s\.0-9]{4,}[0-9])|([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+)|(([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,8})))/,
	// If you want to use this with angularjs validation look into the directive ppDateFuture
	date_future: {
		test: (input:string) => {
			let validDate = /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/.test(input);
			if(!validDate)
				return false;
			let date = new Date(input);
			let midnight = new Date();
			midnight.setHours(0,0,0,0);
			return date.getTime() > midnight.getTime();
		}
	},
	time_hm: /(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){1,2}/,
	cstm_bic: /^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?$/i,
	username: /([a-z0-9\-_]){4,}/,
	forename: /^([a-zA-Z][a-z0-9\-_]+\s*)+$/,
	always_valid: { test() { return true; } },
	always_invalid: { test() { return false; } }
};
let currency = 'EUR'; // XXX This should actually be specified by the backend in FormInfoService
let currencySymbol = '€'; // XXX This should actually be specified by the backend in InfostarsFormInfo
let BACKEND_DATE_FORMAT = "x"; // "yyyy-MM-DD'T'HH:mm:ss.SSSZZ" "ddd, D MMM YYYY HH:mm:ss ZZ"; // ngx-restangular query parameter encoding is broken, sending spaces and + signs doesn't work properly. See also https://github.com/angular/angular/issues/11058
let BACKEND_DATE_LANG = 'en';
let ADDR_LOADING_STR: '...loading...';
// Attention: needs URL whitelists for the referral header sent by browsers
let GOOGLE_API_KEY = 'AIzaSyDc-kHC2Ikko2uaKruZwW-annPsx_uKn6Q'; // This is from https://console.developers.google.com/project/clear-vision-684/apiui/credential florian.hackenberger@gmail.com
let GOOGLE_API_KEY_GEOCODING = 'AIzaSyAkzDLRreVIng-RCIbntEsRyGdyaco_R58'; // This is from https://console.developers.google.com/apis/credentials/key/23?project=clear-vision-684  florian.hackenberger@gmail.com
let GOOGLE_GEOCODE_REGION = 'de'; // This is used to bias geocoding queries to a certain country
let maxFilterDurationDays = 62;
let infostarsSupportEmail = 'support@gps-infostars.com';
let infostarsSupportPhone = '+43-1-99 71 005';
let momentDayIdxToName:{[key: number]:string} = { 0: 'SU', 1: 'MO', 2: 'TU', 3: 'WE', 4: 'TH', 5: 'FR', 6: 'SA', 7: 'SU' };
let momentDayNameToIdx:{[key: string]:number} = { 'SU': 0, 'MO': 1, 'TU': 2, 'WE': 3, 'TH': 4, 'FR': 5, 'SA': 6 };

// REMEMBER the moment.js translations in index.html when adding new languages
let supportedLangMap:any = {
	en: infostarsLang_en,
	de: infostarsLang_de,
	da: infostarsLang_da,
	sk: infostarsLang_sk,
	it: infostarsLang_it,
	es: infostarsLang_es,
};

function determineLanguageFromBrowserOnly() {
	// Determine the user chosen language first, this will be our fallback
	let language = rendersInBrowser() ? ((window as any).navigator.userLanguage || window.navigator.language) : false; // navigator.userLanguage is an IE fallback
	if(language && language.length > 2)
		language = language.substring(0, 2);
	if(!language || !supportedLangMap[language])
		language = fallbackLang;
	return language;
}

function determineLanguage() {
	// Determine the user chosen language first, this will be our fallback
	let language = determineLanguageFromBrowserOnly();
	// Now check for language in the path
	let path = rendersInBrowser() ? window.location.pathname : '';
	let winLoc = rendersInBrowser() ? window.location.toString() : '';
	let baseHref = null;
	if(rendersInBrowser())
		baseHref = document.getElementsByTagName('base').length > 0 ? document.getElementsByTagName('base')[0].href : null;
	if(baseHref && winLoc.indexOf(baseHref) === 0)
		path = winLoc.substring(baseHref.length);
	let pathLang = (path.length === 2 || (path.length > 2 && path[2] === '/')) ? path.substring(0, 2) : null;
	if(pathLang && supportedLangMap[pathLang])
		language = pathLang;
	if(!supportedLangMap[language])
		language = fallbackLang;
	return language;
}

function getPathWithoutContextAndLang() {
	let path = rendersInBrowser() ? window.location.pathname : '';
	let winLoc = rendersInBrowser() ? window.location.toString() : '';
	let baseHref = null;
	if(rendersInBrowser())
		baseHref = document.getElementsByTagName('base').length > 0 ? document.getElementsByTagName('base')[0].href : null;
	if(baseHref && winLoc.indexOf(baseHref) === 0)
		path = winLoc.substring(baseHref.length);
	let pathLang = (path.length === 2 || (path.length > 2 && path[2] === '/')) ? path.substring(0, 2) : null;
	if(pathLang && supportedLangMap[pathLang])
		return path.length === 2 ? '/' : path.substring(2);
	return path;
}

export function isProductionServer() {
	if(!rendersInBrowser())
		return false; // Server side rendering
	return location.hostname !== 'localhost' && location.hostname !== 'test.gps-infostars.com';
}

export let configBase = {
	mode: null as string, // Will be assigned by environment.X.ts
	// --- Will be filled by setUrlsFromLocation ---
	baseUrl: null as string, // e.g. https://app.gps-infostars.com/infostarsWeb
	basePath: null as string, // e.g. /infostarsWeb used for cookie paths
	restBaseUrl: null as string, // e.g. https://app.gps-infostars.com/infostarsWeb/rest
	// ---
	markerImgWidthHeight: 42,
	ytPrivacyPolicyModalId: 'ytPrivacyPolicyModal',
	defaultCenter: defaultCenter,
	fallbackLang: fallbackLang,
	supportedLangMap: supportedLangMap,
	appFeatures: appFeatures,
	currency: currency,
	currencySymbol: currencySymbol,
	transferState: infostarsTransferState,
	validatorPatterns: validatorPatterns,
	ADDR_LOADING_STR: ADDR_LOADING_STR,
	BACKEND_DATE_FORMAT: BACKEND_DATE_FORMAT,
	BACKEND_DATE_LANG: BACKEND_DATE_LANG,
	GOOGLE_API_KEY: GOOGLE_API_KEY,
	GOOGLE_API_KEY_GEOCODING: GOOGLE_API_KEY_GEOCODING,
	GOOGLE_GEOCODE_REGION: GOOGLE_GEOCODE_REGION,
	maxFilterDurationDays: maxFilterDurationDays,
	infostarsSupportEmail: infostarsSupportEmail,
	infostarsSupportPhone: infostarsSupportPhone,
	momentDayIdxToName: momentDayIdxToName,
	momentDayNameToIdx: momentDayNameToIdx,

	appPackage: {} as any, // Will be filled by app.json data

	// Functions
	determineLanguageFromBrowserOnly: determineLanguageFromBrowserOnly,
	determineLanguage: determineLanguage,
	getPathWithoutContextAndLang: getPathWithoutContextAndLang,
};
setUrlsFromLocation(configBase, baseUrl);

<div class="st-container st-effect-7 filter-component" [ngClass]="{ 'st-menu-open': menuOpen }">
	<div class="st-pusher">
		<nav class="st-menu st-effect-7" id="menu-7">
			<h2>{{'filter'|translate}}</h2>
			<div *ngIf="userRights | hasPermission:'SHOW_DISPO_FILTER'">
			<ul [hidden]="hide.startTime && hide.endTime">
				<li class="from-to-filter">
					<date-time [(ngModel)]="searchFilter.start" [label]="'timeFrameFrom'|translate" [useMoment]="true" [required]="true" [hidden]="hide.startTime"></date-time>
					<date-time *ngIf="!hide.startTime" [(ngModel)]="searchFilter.end" [useMoment]="true" [required]="true" [hidden]="hide.endTime" [label]="'to'|translate"></date-time>
					<date-time *ngIf="hide.startTime"  [(ngModel)]="searchFilter.end" [useMoment]="true" [required]="true" [hidden]="hide.endTime" [label]="'timeFrameTo'|translate"></date-time>
					<small class="error" [hidden]="!searchFilterStartEndError">{{ 'alertToDateBeforeFromDate' | translate }}</small>
				</li>
			</ul>
			<ul class="accordion">
				<li class="accordion-navigation">
					<a class="icon icon-data" (click)="onToggleAccordion('settingsProfiles')">
						{{ 'settingsProfiles'|translate }}
					</a>
					<div class="content" [ngClass]="{active: accordionState === 'settingsProfiles'}">
						<select [(ngModel)]="settingsProfile" (ngModelChange)="onSettingsProfileChanged($event)">
							<option *ngFor="let c of selSettingsProfiles" [ngValue]="c">{{ c.name }}</option>
						</select>
						<button class="button dropdown" data-dropdown="filter-save-actions" data-options="align:left" aria-controls="filter-save-actions" aria-expanded="false">
							{{ 'save'|translate}}...
						</button>
						<ul id="filter-save-actions" data-dropdown-content class="tiny f-dropdown" aria-hidden="true">
							<li><a (click)="onSaveSettingsProfile()">{{'save'|translate}}</a></li>
							<li><a (click)="onSaveSettingsProfileAs()">{{'saveAs'|translate}}</a></li>
							<li [hidden]="!(settingsProfile?.name !== 'Standard')"><a (click)="onDeleteSettingsProfile()">{{'delete'|translate}}</a></li>
						</ul>
					</div>
				</li>
				<li class="accordion-navigation" [hidden]="isShowAllVehicles || hide.vehicles">
					<a class="icon icon-data" (click)="onToggleAccordion('vehicleTree')">
						{{ 'vehicleTree' | byTrackType:trackType | translate }}
					</a>
					<div class="content" [ngClass]="{active: accordionState === 'vehicleTree'}">
						<ul>
							<vehicle-tree *ngIf="vehicleTree.length > 0" [vehicleNode]="vehicleTree[0]" [rootNode]="vehicleTree[0]" [level]="0" [api]="this"></vehicle-tree>
						</ul>
					</div>
				</li>
				<li class="accordion-navigation" [hidden]="hide.drivers">
					<a class="icon icon-data" (click)="onToggleAccordion('DriverCondition')">
						{{ 'driverFilter'|translate }}
					</a>
					<div class="content" [ngClass]="{active: accordionState === 'DriverCondition'}">
						<driver-tree *ngIf="driverData.groupName" [driverNode]="driverData" [level]="0" [api]="this"></driver-tree>
					</div>
				</li>
				<li class="accordion-navigation"  [hidden]="hide.vehConditions">
					<a class="icon icon-data" (click)="onToggleAccordion('MainCondition')">
						{{ 'mainCondition'|translate }}
					</a>
					<div class="main-conditions content" [ngClass]="{active: accordionState === 'MainCondition'}">
						<div class="grid-table full-width">
							<div class="row" *ngFor="let n of selBoolMainStati">
								<div class="column">
									<input [(ngModel)]="searchFilter.vehicleMainCondition" name="mainStatus" type="radio" [value]="n.abbreviation"/>
								</div>
								<div class="column">{{ n?.localeName}}</div>
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation"  [hidden]="hide.vehConditions">
					<a class="icon icon-data" (click)="onToggleAccordion('SecCondition')">
						{{ 'secondaryCondition'|translate }}
					</a>
					<div class="secondary-conditions content" [ngClass]="{active: accordionState === 'SecCondition'}">
						<div class="grid-table full-width">
							<div class="row" *ngFor="let n of selBoolSecStati">
								<div class="column">
									<input [(ngModel)]="n.checked" type="checkbox" (change)="onSecStatusToggled()"/>
								</div>
								<div class="column">{{ n?.localeName}}</div>
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation" *ngIf="userRights | hasPermission:'SHOW_TOUR_STATUS_CUSTOM'" [hidden]="hide.tourStati">
					<a class="icon icon-data" (click)="onToggleAccordion('TourStati')">
						{{ 'tourStatus'|translate }}
					</a>
					<div class="tour-status-filter content" [ngClass]="{active: accordionState === 'TourStati'}">
						<a class="link" (click)="onTourStatiSelectAllNone(true)">{{ 'selectAll' | translate }}</a> /
						<a class="link" (click)="onTourStatiSelectAllNone(false)">{{ 'selectNone' | translate }}</a>
						<div class="grid-table full-width">
							<div class="row" *ngFor="let c of selTourStatus">
								<div class="column">
									<input [(ngModel)]="c.checked" type="checkbox" (change)="onTourStatiToggled()"/>
								</div>
								<div class="column">{{ c?.name}}</div>
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation"  [hidden]="hide.zones">
					<a class="icon icon-data" (click)="onToggleAccordion('ZoneFilter')">
						{{ 'zoneFilter'|translate }}
					</a>
					<div class="zone-filter content" [ngClass]="{active: accordionState === 'ZoneFilter'}">
						<a class="link" (click)="onZoneSelectAllNone(true)">{{ 'selectAll' | translate }}</a> /
						<a class="link" (click)="onZoneSelectAllNone(false)">{{ 'selectNone' | translate }}</a>
						<div class="grid-table full-width">
							<div class="row" *ngFor="let n of selZones">
								<div class="column">
									<input [(ngModel)]="n.checked" type="checkbox" (change)="onZoneToggled()"/>
								</div>
								<div class="column">{{ n?.name}}</div>
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation"  [hidden]="hide.geoAreas">
					<a class="icon icon-data" (click)="onToggleAccordion('ZoneDisplayFilter')">
						{{ 'geoAreas'|translate }}
					</a>
					<div class="zone-display-filter content" [ngClass]="{active: accordionState === 'ZoneDisplayFilter'}">
						<a class="link" (click)="onDisplayZoneSelectAllNone(true)">{{ 'selectAll' | translate }}</a> /
						<a class="link" (click)="onDisplayZoneSelectAllNone(false)">{{ 'selectNone' | translate }}</a>
						<div class="grid-table full-width">
							<div class="row" *ngFor="let n of selDisplayZones">
								<div class="column">
									<input [(ngModel)]="n.checked" type="checkbox" (change)="onDisplayZoneToggled()"/>
								</div>
								<div class="column">{{ n?.name}}</div>
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation" [hidden]="!(selStaticRoutes && !hide.staticRoutes)">
					<a (click)="onToggleAccordion('RoutesFilter')">
						{{ 'staticRoutes'|translate }}
					</a>
					<div class="static-routes-filter content" [ngClass]="{active: accordionState === 'RoutesFilter'}">
						<div class="grid-table full-width">
							<div class="row" *ngFor="let n of selStaticRoutes">
								<div class="column">
									<input [(ngModel)]="n.checked" type="checkbox" (change)="onStaticRouteToggled()"/>
								</div>
								<div class="column">{{ n?.name}}</div>
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation" [hidden]="hide.mileage">
					<a (click)="onToggleAccordion('MileageFilter')">
						{{ 'mileage'|translate }}
					</a>
					<div class="mileage-filter content" [ngClass]="{active: accordionState === 'MileageFilter'}">
						<div class="grid-table full-width">
							<div class="row slim-gutter">
								<div class="column">
									<input [(ngModel)]="searchFilter.mileageMin" type="number" step="0.1" min="0"/>
								</div>
								<div class="column">Min</div>
								<div class="column">
									<input [(ngModel)]="searchFilter.mileageMax" type="number" step="0.1" min="0"/>
								</div>
								<div class="column">Max</div>
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation" [hidden]="hide.mapSettings">
					<a (click)="onToggleAccordion('MapSettings')">
						{{ 'mapSettings'|translate }}
					</a>
					<div class="map-settings content" [ngClass]="{active: accordionState === 'MapSettings'}">
						<div class="grid-table full-width">
							<div class="row collapse" *ngFor="let n of selMapTypes">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapType" name="mapType" type="radio" [value]="n.id" id="filter-maptype-{{n.id}}"/>
								</div>
								<div class="column">
									<label for="filter-maptype-{{n.id}}">{{ n?.name }}</label>
								</div>
							</div>
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapShowTraffic" type="checkbox" [attr.disabled]="searchFilter.mapType !== 'ROADMAP' || null" id="filter-map-show-traffic"/>
								</div>
								<div class="column">
									<label for="filter-map-show-traffic">{{ 'traffic'|translate}}</label>
								</div>
							</div>
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.disableClusteringOnMap" type="checkbox" id="filter-disable-clustering"/>
								</div>
								<div class="column">
									<label for="filter-disable-clustering">{{ 'disableClustering'|translate }}</label>
								</div>
							</div>
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.enableTracking" type="checkbox" id="filter-enable-tracking">
								</div>
								<div class="column">
									<label for="filter-enable-tracking">{{ 'enableTracking'|translate }} <i class="fi-alert" title="{{ 'trackingPermissionDenied' | translate }}" [hidden]="gpsTrackingAvail"></i></label>
								</div>
								<!-- <div class="column">{{ 'enableTracking'|translate }}</div> -->
							</div>
						</div>
					</div>
				</li>
				<li class="accordion-navigation"  [hidden]="hide.mapSection">
					<a (click)="onToggleAccordion('MapSection')">
						{{ 'mapSection'|translate }}
					</a>
					<div class="map-section content" [ngClass]="{active: accordionState === 'MapSection'}">
						<div class="grid-table full-width">
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapSection" name="mapSection" type="radio" [value]=""/>
								</div>
								<div class="column">{{ 'deactivated'|translate}}</div>
							</div>
							<div class="row" *ngFor="let n of selMapSections">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapSection" name="mapSection" type="radio" [value]="n"/>
									<a class="button secondary microscopic" (click)="onDeleteMapSection(n)" title="{{'delete'|translate}}"><i class="fi-trash"></i></a>
								</div>
								<div class="column">{{ n?.name}}</div>
							</div>
						</div>
						<a class="button" (click)="onCreateMapSection()" if-permission="and('ADD_MAP_SECTIONS','EDIT_MAP_SECTIONS')">{{'create'|translate}}</a>
					</div>
				</li>
				<li class="accordion-navigation" [hidden]="hide.mapRefresh">
					<a (click)="onToggleAccordion('MapRefresh')">
						{{ 'Refresh'|translate }}
					</a>
					<div class="map-section content" [ngClass]="{active: accordionState === 'MapRefresh'}">
						<div class="grid-table full-width">
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapRefreshMinutes" name="map" type="radio" [value]="0"/>
								</div>
								<div class="column">{{ 'deactivated'|translate}}</div>
							</div>
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapRefreshMinutes" name="map" type="radio" [value]="1"/>
								</div>
								<div class="column">1 Min</div>
							</div>
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapRefreshMinutes" name="map" type="radio" [value]="2"/>
								</div>
								<div class="column">2 Min</div>
							</div>
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapRefreshMinutes" name="map" type="radio" [value]="3"/>
								</div>
								<div class="column">3 Min</div>
							</div>
						</div>
						<div class="grid-table full-width">
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.mapRefreshMinutes" type="number" step="{{ refreshStep}}" min="0"/>
								</div>
								<div class="column">Min</div>
							</div>
						</div>
						<div class="grid-table full-width">
							<div class="row">
								<div class="column">
									<input [(ngModel)]="searchFilter.rearrangeMapOnAutoRefresh" type="checkbox"/>
								</div>
								<div class="column">{{ 'rearrangeMapOnAutoReload'|translate}}</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			</div>
		</nav>
		<!-- Include the inner HTML of the filter tag here -->
		<ng-content class="st-content"></ng-content>
	</div>
</div>

import { Inject, EventEmitter, Injectable, PLATFORM_ID } from "@angular/core";
import { Location, isPlatformServer } from '@angular/common';
import { InfostarsToolsService } from "./InfostarsTools.service";
// import { ClientLocationService } from "./ClientLocation.service";
import { config, rendersInBrowser } from "../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { SEOService } from "./SEO.service";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationError, NavigationStart, ActivationStart } from '@angular/router';
// import { FormInfoService } from "./FormInfo.service";
import { BaseComponent } from '../base.component';
import { filter, first, skip } from "rxjs/operators";
import { LocalStorageService } from './LocalStorage.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { timer } from "rxjs";
import { build } from "protobufjs";
import { MessagesService } from "./messages.service";
import { LoginService } from "./login.service";

@Injectable()
export class AppInitService extends BaseComponent {
	firstLoad = true;
	urlOnLoadPath = null as string;
	buildInfoPollIntervalMs = 10 * 60 * 1000; // 10 minutes
	constructor(
		public $translate: TranslateService,
		protected $location: Location,
		protected $router: Router,
		protected $http: HttpClient,
		protected InfostarsTools: InfostarsToolsService,
		protected Login: LoginService,
		protected SEO: SEOService,
		protected LocalStorage: LocalStorageService,
		protected Messages: MessagesService,
		protected TransferState: TransferState,
		@Inject(PLATFORM_ID) protected platformId: Object,
	) {
		super(InfostarsTools);
	}
	init():any {
		this.fixLocationHash();
		const locHash = this.getLocationHash();
		this.urlOnLoadPath = this.$location.path();
		if((!this.urlOnLoadPath || '' === this.urlOnLoadPath) && locHash && '#!' !== locHash && '#!/' !== locHash && locHash.length > 3)
			this.urlOnLoadPath = locHash.substring(2); // We might have no URL path but a hash (user clicked an old link external link to our app)
		if(locHash && locHash !== "#!" && locHash !== "#!/") {
			this.$router.navigateByUrl(this.urlOnLoadPath);
		}
		if(this.urlOnLoadPath === '/secret')
			this.LocalStorage.add('secretPageVisited', 'true');

		let currActiveLang = this.InfostarsTools.activeLang;
		this.setDefaultTitleAndDescription(); // Updates the SEO meta tags
		this.subscribe(this.InfostarsTools.activeLang$, lang => {
			if(currActiveLang === lang)
				return;
			currActiveLang = lang;
			this.setDefaultTitleAndDescription();
		});

	//	if(InfostarsTools.isMobile()) {
	//		$rootScope.clientIntroService.clickNext();
	//	}
		this.$location.onUrlChange(() => {
			// this.Analytics.pageView();
		});
		this.subscribe(this.$router.events.pipe(filter(event => event instanceof NavigationError)), (ev: NavigationError) => {
			console.error('Navigation Error', ev.error);
		});

		// Retrieve the revision information from the app.json file generated by gulp/bump.js
		const tsAppJsonKey = makeStateKey(config.transferState.appJson);
		if(this.TransferState.hasKey(tsAppJsonKey)) {
			config.appPackage = this.TransferState.get(tsAppJsonKey, {} as any);
		}else {
			// app.json is a generated file which includes our revision control version info, see webpack.angular.js
			this.$http.get(config.baseUrl + '/actuator/info').subscribe((data: any) => {
				if(data && data?.git?.branch)
					data.git.branch = data.git.branch.replace('origin/', ''); // We don't need the name of the remote in the UI
				config.appPackage = data;
				if(isPlatformServer(this.platformId))
					this.TransferState.set(tsAppJsonKey, data);
			});
		}
		// Watch the backend for new versions being deployed
		this.subscribe(timer(this.buildInfoPollIntervalMs, this.buildInfoPollIntervalMs), () => {
			this.$http.get(config.baseUrl + '/actuator/info').subscribe((data) => {
				let newAppPackage = data as any;
				if(newAppPackage && newAppPackage?.git?.commit?.id != config.appPackage?.git?.commit?.id) {
					this.Messages.warning(this.$translate.instant('newVersionAvailablePleaseUpdate'));
					this.Login.notifyUpdateAvailable(newAppPackage.build.ciBuildId);
				}
			});
		})

		// Insert the paymill script after we have received the paymill public key
		// See https://developers.paymill.com/en/reference/paymill-bridge/ for paymill docs
		// this.FormInfoService.getFormInfo().then((formInfo) => {
			// window.PAYMILL_PUBLIC_KEY = formInfo.paymillPublicKey;
			// $('<script>').attr('type', 'text/javascript').attr('src', 'https://bridge.paymill.com/').appendTo('body');
		// });

		// ATTENTION: The following code does redirects, if you also want to redirect, do it in here
		this.$router.events.pipe(filter(event => event instanceof ActivationStart)).pipe(first()).subscribe((ev: ActivationStart) => {
			var skipLocationCheck = false; // Set explicitly if the page can be viewed withouth a location check
			var isRootPage = false; // Whether we are navigating to one of the pages with MainCtrl
			const rData = this.InfostarsTools.getAllRouteData(ev.snapshot.root);
			isRootPage = (rData && rData.isRootState) || isRootPage;
			skipLocationCheck = (rData && rData.skipLocationCheck) || skipLocationCheck;

			// Example rediret
			// if(this.urlOnLoadPath === '/secret') {
			// 	this.$router.navigate(['/', this.InfostarsTools.activeLang, 'secretpage']);
			// 	return;
			// }
		});
		// ATTENTION: DO NOT add any code below here in this method
		return Promise.resolve();
	}
	private getLocationHash() {
		if(!rendersInBrowser())
			return null; // No location.hash available, server side rendering
		return location.hash;
	}
	private fixLocationHash() {
		if(!rendersInBrowser())
			return; // No location.hash available, nothing to fix
		//Make sure our old links don't break with our fragment change from # to #!
		if(location.hash.length > 1 && location.hash[0] === '#' && location.hash[1] !== '!')
			location.hash = '#!' + location.hash.substring(1);
	}
	private setDefaultTitleAndDescription() {
		this.SEO.setDefaultTitle('Infostars Tracking'); //this.$translate.instant('site_title'));
		this.SEO.setDefaultDescription('GPS tracking by Infostars - devices and software'); //this.$translate.instant('site_description'));
		this.SEO.setDefaultKeywords(''); //this.$translate.instant('site_keywords'));
	}
}

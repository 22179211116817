import { Inject, EventEmitter, Injectable } from "@angular/core";
export class MessageInfo {
	public newMessage:any;
	public messages:Array<any>;
}
/**
 * @ngdoc function
 * @name infostars.service:Messages
 * @description
 * # Messages
 * Utility service for displaying messages to the user in response to e.g. ajax requests.
 * Relies on the messages component to be included on the root index.html page
 */
@Injectable()
export class MessagesService {
	private messages:Array<any> = [];
	public messages$ = new EventEmitter<MessageInfo>();

	constructor() {}

	private addMsg(message:any, timeout:number, cssClasses:string) {
		// Don't show duplicate messages
		if(this.messages.find((msg:any) => msg.text === message) !== undefined)
			return;
		var htmlId = 'msg-' + new Date().getTime();
		let msgObj = Object.assign(message, {'timeout': timeout, 'cssClasses': cssClasses, 'id': htmlId});
		this.messages.push(msgObj);
		let msgInfo = new MessageInfo();
		msgInfo.messages = this.messages;
		msgInfo.newMessage = msgObj
		this.messages$.emit(msgInfo);
	}
	public success(msg:string, timeout?:number, cssClasses?:string) {
		this.addMsg({level: 'success', text: msg}, timeout, cssClasses);
	}
	public info(msg:string, timeout?:number, cssClasses?:string) {
		this.addMsg({level: 'info', text: msg}, timeout, cssClasses);
	}
	public warning(msg:string, timeout?:number, cssClasses?:string) {
		this.addMsg({level: 'warning', text: msg}, timeout, cssClasses);
	}
	public error(msg:string, timeout?:number, cssClasses?:string) {
		this.addMsg({level: 'alert', text: msg}, timeout, cssClasses);
	}
}

import { Component, OnInit, OnDestroy, Inject, AfterViewInit, ViewChild } from "@angular/core";
import { InfostarsToolsService } from "./services/InfostarsTools.service";
import { SEOService } from "./services/SEO.service";
import { config } from "./environments/environment";
import { DOCUMENT } from "@angular/common";
import { ShowNavService } from "./services/ShowNav.service";
import { LoginService } from "./services/login.service";
import { BaseComponent } from './base.component';
import { CanvasToPngComponent } from "./components/canvas-to-png.component";
import { CanvasToPngService } from "./services/canvas-to-png.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.html',
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('canvasToPng') protected cvToPngCmp:CanvasToPngComponent;

	public config:any;
	public isPrerenderProxy = false;
	public seo = this.SEO.seo;
	public fullHeight = false;
	public showNav = this.ShowNav.showNav;
	public title = false;
	public termsAndConditionsHtml = '';
	public ytPrivacyPolicyHtml = '';

	private langLinkMap = new Map<string, HTMLLinkElement>();

	constructor(
		@Inject(DOCUMENT) private doc: Document,
		@Inject(InfostarsToolsService) protected InfostarsTools: InfostarsToolsService,
		@Inject(ShowNavService) public ShowNav: ShowNavService,
		@Inject(SEOService) private SEO: SEOService,
		@Inject(LoginService) private Login: LoginService,
		@Inject(CanvasToPngService) private cvToPng: CanvasToPngService,
	) {
		super(InfostarsTools);
		this.config = config;
		this.isPrerenderProxy = InfostarsTools.isPrerenderProxy;
	}

	ngAfterViewInit(): void {
		this.cvToPng.setCanvasToPngComponent(this.cvToPngCmp);
	}

	ngOnInit() {
		const loadAnimEl = this.doc.getElementById('loadingSpinner');
		if(loadAnimEl)
			loadAnimEl.setAttribute('hidden', 'true'); // We're done loading
		// Add head link tags to the page in other languages for crawlers -->
		this.subscribe(this.InfostarsTools.activeLang$, () => {
			['en', 'de', 'it', 'default'].forEach(lang => {
				if(this.InfostarsTools.otherLangURLs[lang])
					this.setLangLink(this.InfostarsTools.otherLangURLs[lang], lang, 'alternate');
				else
					this.removeLangLink(lang, 'alternate');
			});
		});
		this.subscribe(this.ShowNav.showNav$, showNav => this.showNav = showNav);
		this.subscribe(this.SEO.seo$, () => {
			this.seo = this.SEO.seo;
		});
		this.subscribe(this.Login.termsAndConditionsHtml$, tncHtml => this.termsAndConditionsHtml = tncHtml);
	 	this.ytPrivacyPolicyHtml = ''; // XXXAngularIO looks like we never had a priv policy here, but we need one
	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private setLangLink(href:string, hreflang:string, rel:string) {
		let key = 'hreflang:' + hreflang + 'rel:' + rel;
		let linkEl = this.langLinkMap.get(key);
		if(!linkEl) {
			linkEl = this.doc.createElement('link');
			linkEl.setAttribute('href', href);
			linkEl.setAttribute('hreflang', hreflang);
			linkEl.setAttribute('rel', rel);
			this.doc.head.appendChild(linkEl);
			this.langLinkMap.set(key, linkEl);
		}
		linkEl.setAttribute('href', href);
	}
	private removeLangLink(hreflang:string, rel:string) {
		let key = 'hreflang:' + hreflang + 'rel:' + rel;
		let linkEl = this.langLinkMap.get(key);
		if(!linkEl)
			return;
		this.doc.head.removeChild(linkEl);
		this.langLinkMap.delete(key);
	}

	public onAcceptTerms() {
		this.Login.onTncAccepted();
	}

	public onDeclineTerms() {
		this.Login.onTncDeclined();
	}

	public onCloseYTPP() {
		($('#' + config.ytPrivacyPolicyModalId) as any).foundation('reveal', 'close');
	}
}

import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { config } from "../environments/environment";
import { ShowNavService } from "../services/ShowNav.service";
import { BaseComponent } from '../base.component';

@Component({
	selector: 'logo-header-bar',
	templateUrl: 'logo-header-bar.html',
})
export class LogoHeaderBarCtrl extends BaseComponent implements OnInit, OnDestroy {
	public imgBase = config.baseUrl + '/';
	public fillerStyle = {
		background: 'transparent url("' + config.baseUrl + '/images/header_bg.png") repeat scroll center center',
		height: '102px'
	};
	public logoMaxHeight = '300px';// XXXAngularIO $rootScope.logoHeaderShown ? '0px' : '300px';
	ngOnInit(): void {
		//XXXAngularIO if($rootScope.logoHeaderShown)
		// 	return;
		setTimeout(() => {
			this.logoMaxHeight = '0px';
			//XXXAngularIO $rootScope.logoHeaderShown = true;
		}, 5000);

	}
	ngOnDestroy(): void {
		super.ngOnDestroy();
	}
}
